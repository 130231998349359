import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import { Divider } from '@/components/ui/Divider';
import { graphql, useStaticQuery } from 'gatsby';

export default function ChiSiamo() {
  const pageTitle = 'Modulistica';

  const Banner = () => (
    <StaticImage
      src="../images/banners/modulistica.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const {
    allWpDocument: { nodes: docs },
  } = useStaticQuery(graphql`
    query {
      allWpDocument {
        nodes {
          title
          documents {
            files {
              title
              file {
                filename
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div style={{ maxWidth: '1280px' }} className="container mt-5 mb-5">
        <Divider size={3} />
        <div className="row">
          <div className="col-12 text-primary">
            <h1 className="h3 font-rubik">
              In questa sezione trovi tutti i moduli necessari per attivare i
              nostri servizi
            </h1>
            {docs.map((doc: any, index: number) => (
              <div className="mt-4" key={index}>
                <h2 className="font-rubik text-secondary h4">{doc.title}</h2>
                {doc.documents.files.map((file: any, i: number) => (
                  <div className="row gx-1" key={i}>
                    <div className="col-auto">
                      <StaticImage
                        className="me-1 mt-2"
                        src="../images/icons/download.svg"
                        alt="download"
                      />
                      <a
                        className="text-secondary"
                        href={`https://backoffice.vicenzaparcheggi.it/wp-content/uploads/${file.file.filename}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {file.title}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <Divider size={5} />
      </div>
    </Layout>
  );
}
